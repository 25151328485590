<!-- =========================================================================================
    File Name: CardStatistics.vue
    Description: Statistics Card
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="page">
    <!-- swiper -->
    <swiper :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
      <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%"></div>
      <swiper-slide class="swiper-slide details">
        <div class="content-slider">
          <div class="title" data-swiper-parallax="-100">Détails du rendez-vous : <br>
         </div>

        </div>
      </swiper-slide>
    </swiper>
    <div class="container  negociateur">
      <vx-card title="Détails du rendez-vous ">

      <div v-if="rendezVous !== null" class="ad" style="border: none">
        <div v-if="rendezVous.ad">
          <vs-row>
            <table class="detailsRDV">
              <tr>
                <td rowspan="3" style="padding: 0"><img class="imageRdv" :src="rendezVous.ad.photo"></td>
                <td class="header" v-if="rendezVous.tradeType">
                  {{rendezVous.ad.tradeType.name}}
                </td>
                <td class="header"><b>Réf :</b> {{rendezVous.ad.reference}}
                  <div class="float-right">{{rendezVous.ad.surface}} m<sup>2</sup></div>
                </td>
                <td class="header">{{rendezVous.ad.price | priceFormat}}</td>
              </tr>
              <tr>
                <td colspan="3"> {{rendezVous.ad.description}}
                  <div class="adLinkBlock">
                    <a :href="rendezVous.ad.adUrl" target="_blank" class="adLinkDetails">
                      <feather-icon svgClasses="w-4 h-4" icon="LinkIcon" class="mr-2"/>
                      Lien de l'annonce</a></div>
                </td>
              </tr>
            </table>
          </vs-row>
        </div>
        <p class="idAd"> Rendez vous d'identifiant <span>{{rendezVous.id}}</span> de la commande <span
          v-if="rendezVous.commande"><a :href="'/commandes/'+rendezVous.commande.id" target="_blank" >{{rendezVous.commande.reference}} <feather-icon svgClasses="w-4 h-4" icon="ExternalLinkIcon" class="mr-2"/></a></span>
        </p>
        <vs-row class="corpsRDV mt-6">
          <vs-col v-if="rendezVous.ad !== undefined &&  rendezVous.ad !== null" vs-justify="center" vs-align="center" vs-w="3" style="background: #ffbd02;">
            <feather-icon svgClasses="w-4 h-4" icon="UserIcon" class="mr-2"/>
            <b>Vendeur </b><br>
            {{rendezVous.ad.nomVendeur}}<br>
            <br>

              <feather-icon svgClasses="w-4 h-4" icon="MapIcon" class="mr-2"/>
              {{rendezVous.ad.postalCode}} {{rendezVous.ad.adCity}}
              <br>
              <feather-icon svgClasses="w-4 h-4" icon="PhoneCallIcon" class="mr-2"/>
              {{rendezVous.ad.mobileNumber}}
          </vs-col>
          <vs-col vs-justify="center" vs-align="center" vs-w="3">
               <span class="statut"><feather-icon svgClasses="w-4 h-4" icon="ApertureIcon"
                                                  class="mr-2"/><b>Statut : </b>
                 <div class="withOverlay">
                  <feather-icon v-if=" this.oldStatut == 'En attente d\'annulation'"  svgClasses="w-4 h-4" :icon="iconAnnulation" class="mr-2 " />
                <div class="alt-overlay">
                <div class="text">{{ labelAnnulation }}</div>
            </div>
                 </div>

          <select class="vs-select vx-col w-12/12 mb-5" v-model="rendezVous.statut" :disabled="this.oldStatut == 'Réalisé' || this.oldStatut == 'Annulé' || this.oldStatut == 'En attente d\'annulation' ? true : false">
            <option v-for="item in optionsStatut" :key="item.key" :value="item.key" :disabled="item.disabled">{{item.value}}</option>
          </select></span>
            <br>
            <feather-icon svgClasses="w-4 h-4" icon="CalendarIcon" class="mr-2 " />
            <b>Date : </b>{{ rendezVous.dateDebut | formDate }}

          </vs-col>
          <vs-col vs-justify="center" vs-align="center" vs-w="3">
              <textarea
                type="text"
                v-model="rendezVous.commentaire"
                class="w-full textarea"
                placeholder="Notes précisions"
              >Notes précisions </textarea>
          </vs-col>
          <vs-col vs-justify="center" vs-align="center" vs-w="3">
            <feather-icon svgClasses="w-4 h-4" icon="FileTextIcon" class="mr-2"/>
            <b>Numéro du mandat :</b>
            <vs-input
              class="w-full mb-2"
              :icon="mandatIcon"
              icon-pack="feather"
              :disabled="this.rendezVous.statut !== 'Réalisé'"
              :class="mandatValide"
              type="text"
              v-model="rendezVous.mandat"
              name="mandat"
              @change="validerLongeurMandat"
            ></vs-input>
            <vs-button  color="primary" type="border" icon-pack="feather" icon="icon-search" @click="validerMandat">Valider n° mandat</vs-button>
          </vs-col>
        </vs-row>

        <vs-row class="mt-20">
          <vs-col vs-justify="center" vs-align="center" vs-w="12">
            <div v-if="rendezVous.teleconseiller">
              <b>Téleconseiller : </b> {{rendezVous.teleconseiller.firstName}} {{rendezVous.teleconseiller.lastName}}
              <br>Dernière mise à jour <b>: {{dateMiseAjour}} </b>Par <b>{{ whoUpdated}}</b>
            </div>
          </vs-col>
        </vs-row>
        <vs-row  v-if=" this.oldStatut !== this.rendezVous.statut && this.rendezVous.statut == 'Annulé'" class="mt-5 mb-10">
          <vs-col vs-justify="center" vs-align="center" vs-w="12">
            <vs-alert color="warning" title="Alerte" active="true">
              Vous devez passer une demande d'annulation.
            </vs-alert>
          </vs-col>
        </vs-row>
        <vs-row  v-if=" this.oldStatut !== this.rendezVous.statut && this.rendezVous.statut == 'Réalisé'" class="mt-5">
          <vs-col vs-justify="center" vs-align="center" vs-w="12">
            <vs-alert color="success" title="Alerte" active="true">
              Si vous avez eu un mandat, n'oubliez pas de mettre son numéro.
            </vs-alert>
          </vs-col>
        </vs-row>
        <!-- Demande d'annulation du rendez-vous -->
        <vs-row class="corpsRDV annulation" v-if=" this.oldStatut !== this.rendezVous.statut && this.rendezVous.statut == 'Annulé'" >
          <h3 class="titreRDV">Demande d'annualtion du rendez-vous</h3>
          <p>J'effectue cette demande d'annulation du RDV avec le vendeur <b v-if="this.rendezVous.ad">{{this.rendezVous.ad.nomVendeur}} </b> pour le motif suivant : </p>
          <v-select :options="optionsMotif"
                    placeholder="Motif d'annulation *"
                    v-model="motifAnnulation"
                    class="mt-5 w-full"/>
          <br>
          <vs-checkbox class="mt-5 w-full" name="confirmation" v-model="confirmation"
          >Avez-vous confirmé le rdv au vendeur?
          </vs-checkbox><br>
          <vs-checkbox class=" w-full" name="confirmation" v-model="vendeurRencontre">Avez-vous rencontré le vendeur?
          </vs-checkbox><br>
          <textarea
            label-placeholder="Commentaires"
            type="text"
            v-model="commentaireAnnulation"
            class="w-full textarea mt-5 "
            placeholder="Précision *"
          />

        </vs-row>
        <div class="mt-6 pb-10 float-right">
          <vs-button class="mr-3 mb-2 " @click="editEvent()" :disabled="(this.oldStatut == 'Réalisé' || this.oldStatut == 'Annulé' || this.oldStatut == 'En attente d\'annulation') && this.oldMandat == rendezVous.mandat ">Mettre à jour</vs-button>
        </div>
      </div>
        <br>
      </vx-card>
      <!-- Tableau -->

    <div class="vx-col w-full mb-base mt-20">
      <vx-card title="Historique" @refresh="closeCardAnimationDemo" action-buttons>
        <vx-timeline :data="timelineData" />
      </vx-card>
    </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import vSelect from 'vue-select';
// import router from '../router';
import imageRdv from '@/assets/images/pages/historique.png';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import VxTimeline from '@/components/timeline/VxTimeline';
import { colors } from '../../../../themeConfig';
import axios from '../../../axios.js';
import router from '../../../router';

export default {
  components: {
    swiper,
    swiperSlide,
    'v-select': vSelect,
    VxTimeline,
  },
  props: ['id'],
  filters: {
    formDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
      }
    },
    priceFormat(value) {
      if (value) {
        const val = (value / 1).toFixed(0).replace('.', ' ');
        return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} € `;
      }
    },
  },
  data() {
    return {
      oldMandat: '',
      mandatIcon: 'icon-help',
      timelineData: [],
      mandatValide: '',
      secteur: [],
      enAttente: '',
      colors,
      Suspendus: '',
      enCours: '',
      Clotures: '',
      commandes: '',
      titreCommande: '',
      rendezVous: null,
      imageRdv,
      nomVendeur: '',
      motifAnnulation: '',
      confirmation: false,
      rdvConfirme: false,
      vendeurRencontre: false,
      commentaireAnnulation: '',
      iconAnnulation: '',
      labelAnnulation: '',
      dateMiseAjour: '',
      whoUpdated: '',
      optionsStatut: [
        { key: 'A venir', value: 'A venir', disabled: true },
        { key: 'A statuer', value: 'A statuer', disabled: true },
        { key: 'Réalisé', value: 'Réalisé' },
        { key: 'Annulé', value: 'Annulé' },
        { key: 'En attente d\'annulation', value: 'En attente d\'annulation', disabled: true },
      ],
      optionsMotif: ['Vendeur absent', 'Vendeur annule le rdv', 'Bien vendu', 'Mandat exclusif irrévoccalbe', 'Mandat signé par un confrère pp', 'Autre'],
      today: new Date(),
      oldStatut: '',

    };
  },
  methods: {
    validerLongeurMandat() {
      if (this.rendezVous.mandat.length !== 6) {
        this.rendezVous.mandat = this.oldMandat;
        this.$toasted.show('Le numéro de mandat doit contenir 6 caractéres!').goAway(1500);
      }
    },
    closeCardAnimationDemo(card) {
      card.removeRefreshAnimation(3000);
    },
    validFormAnnulation() {
      return this.motifAnnulation !== '' && this.commentaireAnnulation !== '';
    },
    editEvent() {
      if ((!!(this.oldStatut == 'Réalisé' || this.oldStatut == 'Annulé' || this.oldStatut == "En attente d'annualtion")) && this.oldMandat == this.rendezVous.mandat) {
        this.$vs.notify({
          title: 'Avertissement',
          text: 'Vous ne pouvez pas modifier ce rendez-vous.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          time: 5000,
        });
        return;
      }
      const numMandat = this.rendezVous.mandat;
      const { trigramme } = this.rendezVous.negociateur;

      axios.get(`/api/mandat/${this.$store.state.AppActiveUser.user.id}/${numMandat + trigramme}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          let mandat = '';
          const obj = {
            user: this.$store.state.AppActiveUser.user.id,
            id: this.id,
            statut: this.rendezVous.statut,
            commentaire: this.rendezVous.commentaire,
            idRdv: this.rendezVous.id,
          };
          if (this.rendezVous.statut == 'Annulé') {
            obj.statut = 'En attente d\'annulation';
          }
          if (this.rendezVous.statut == 'Réalisé' && this.rendezVous.mandat !== null) {
            if (response.status === 200) {
              mandat = this.rendezVous.mandat;
              obj.mandat = mandat;
            } else {
              this.$toasted.show('Le mandat ne sera pas enregistré, il est invalide! <br> Si votre mandat a été publié  depuis moins de 24h merci de réessayer  dans 24h. ').goAway(4000);
            }
          }
          if (this.oldStatut !== this.rendezVous.statut && this.rendezVous.statut == 'Annulé') {
            if (this.validFormAnnulation()) {
              const objAnnulation = {
                dateDemande: moment().format('YYYY-MM-DD HH:mm'),
                negociateur: `/api/mandataries/${this.$store.state.AppActiveUser.user.id}`,
                rendezVous: `/api/rendez_vouses/${this.rendezVous.id}`,
                teleconseiller: `/api/employees/${this.rendezVous.teleconseiller.id}`,
                commande: `/api/commandes/${this.rendezVous.commande.id}`,
                description: this.nomVendeur,
                commentaire: this.commentaireAnnulation,
                statut: 'A traiter',
                motif: this.motifAnnulation,
                rdvConfirme: this.confirmation,
                vendeurRencontre: this.vendeurRencontre,
              };
              axios.post('/api/demande_annulations', objAnnulation, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
              });
            } else {
              this.$vs.notify({
                title: 'Avertissement',
                text: 'Veuillez remplir la demande d\'annulation',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning',
                time: 5000,
              });
              return;
            }
          }
          axios.patch(`/api/rendez_vouses/${this.id}`, obj, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              'Content-type': 'application/merge-patch+json',
            },
          }).then(() => {
            this.getRdv();
            this.getHistorique(this.id);
          });
          this.$vs.notify({
            title: 'Succès',
            text: 'Le rendez-vous a été mis à jour!',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success',
            time: 5000,
          });
        });
    },
    validerMandat() {
      const numMandat = this.rendezVous.mandat;
      const { trigramme } = this.rendezVous.negociateur;
      axios.get(`/api/mandat/${this.$store.state.AppActiveUser.user.id}/${numMandat + trigramme}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          this.$toasted.show(response.data).goAway(4000);
          this.mandatValide = response.status === 200 ? 'mandat-valide' : 'mandat-invalide';
          this.mandatIcon = response.status === 200 ? 'icon-check-circle' : 'icon-x-circle';
        });
    },

    checkAnnulation(rdvId) {
      axios.get(`/api/demande_annulations?rendezVous=${rdvId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          if (response.data !== null && response.data.length > 0) {
            if (response.data[0].statut == 'A traiter') {
              this.iconAnnulation = 'AlertCircleIcon';
              this.labelAnnulation = 'Demande en cours de traitement';
            } else if (response.data[0].statut == 'Refusé') {
              this.iconAnnulation = 'XOctagonIcon';
              this.labelAnnulation = 'Demande refusée, pas de remboursement';
            } else {
              this.iconAnnulation = 'CheckCircleIcon';
              this.labelAnnulation = 'Demande acceptée';
            }
          }
        });
    },
    getHistorique(id) {
      const colors = ['primary', 'warning', 'danger', 'success'];
      axios.get(`/api/historique_rdvs?rendezVous=${id}&order[dateAction]=desc`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((responseRdv) => {
          let oneLine = {};
          const timeline = [];
          const { data } = responseRdv;
          let i = (responseRdv.data).length;
          data.forEach((value) => {
            oneLine = {
              color: colors[Math.floor(Math.random() * colors.length)],
              icon: value.libelle == 'Creation' ? 'PlusIcon' : 'EditIcon',
              title: value.libelle,
              desc: `${value.text}\n \nPar ${value.who}`,
              time: value.dateAction,
            };
            if (value.libelle == 'Mise à jour') {
              oneLine.title = `${value.libelle} ${i}`;
              i--;
            }
            timeline.push(oneLine);
          });
          this.timelineData = timeline;
          if (responseRdv.data.length !== 0) {
            this.whoUpdated = responseRdv.data[0].who;
            this.dateMiseAjour = moment(responseRdv.data[0].dateAction).format('YYYY-MM-DD HH:mm:ss ');
          } else {
            this.whoUpdated = '';
            this.dateMiseAjour = '';
          }
        });
    },
    getRdv() {
      axios.get(`/api/rendez_vouses/${this.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      })
        .then((response) => {
          this.rendezVous = response.data;
          this.checkAnnulation(response.data.id);
          this.oldStatut = response.data.statut;
          this.oldMandat = response.data.mandat;
          this.getHistorique(response.data.id);
        })
        .catch((error) => {
          if (error.response !== null) {
            if (error.response) {
              this.$vs.notify({
                title: 'Erreur',
                text: "Vous n'êtes pas autorisé à accéder à ce rendez-vous",
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'warning',
                time: 4000,
              });
              router.push('/rendez-vous/avenir');
            }
          } else {
            this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
          }
        });
    },
  },
  created() {
    this.getRdv();
  },
};
</script>
<style>
  .swiper-slide.details {
    background: url("../../../assets/images/Slider/details.png");
    background-size: cover;
  }

</style>
